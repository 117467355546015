import React, { useState, useRef, useEffect } from "react";
import "../Products/product.css";

import CancelButton from "../../reusable/Buttons/CancelButton";
import AddButton from "../../reusable/Buttons/AddButton";
import ButtonWithProgress from "../../reusable/Buttons/ButtonWithProgress";
function EditAgentDialog({ onClose, editUser, buttonloading ,editData
 
  
}) {
  const [userData, setUserData] = useState({
  name:"",
  phone:"",
  email :"",
  });
  const [errors, setError] = useState({});
  
  const userNameRef = useRef(null);
  const userPhoneRef = useRef(null);
  const userEmailRef = useRef(null);
  useEffect(() => {
    if (editData) {
        setUserData(editData);
    }
  }, [editData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
   
    let trimmedValue;
    if (name === "phone" || name === "email" ) {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }
    setUserData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
    
  };
  const validateFields = () => {
    let valid = true;
    const newErrors = {};
  
    // Validate Name
    if (!userData.name) {
      newErrors.name = "This field must not be empty. Please enter a value.";
      valid = false;
      userNameRef.current.focus();
    }
    else if (!userData.phone) {
        newErrors.phone = "This field must not be empty. Please enter a value.";
        valid = false;
        userPhoneRef.current.focus();
      } 
      else if (userData.phone.length !== 10) {
        newErrors.phone = "Phone Number must be 10 digits.";
        valid = false;
        userPhoneRef.current.focus();
      } else {
        newErrors.phone = "";
      }
    
  
 if (userData.email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userData.email)) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
      userEmailRef.current.focus();
    } else {
      newErrors.email = "";
    }
  
    // Validate Phone
 
    setError(newErrors);
    return valid;
  };
  
  const handleUpdate = () => {
    console.log("add", userData);
    if (!validateFields()) {
      return;
    }
    
    editUser(userData);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  
  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-scrollable"
          onClick={stopPropagation}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Edit Agent</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="off"
                      value={userData.name}
                      onChange={handleChange}
                      ref={userNameRef}
                    />
                    {errors.name && (
                      <div className="error-message invalid-feedback">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <>
                    <label className="form-label">
                    Phone Number <span className="text-danger">*</span>
                    </label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          id="basic-addon41"
                          style={{
                            backgroundColor: "#ededff",
                            color: "#242c6d !important",
                          }}
                        >
                          +91
                        </span>
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control"
                          fdprocessedid="v93442"
                          autoComplete="off"
                          name="phone"
                          value={userData.phone}
                          ref={userPhoneRef}
                         
                          onChange={(e) => {
                            const value = e.target.value;

                            if (/^\d{0,10}$/.test(value)) {
                              handleChange(e);
                            }
                          }}
                        />
                        {errors.phone && (
                          <div className="error-message invalid-feedback">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                  
                  

                  <div className="col-lg-12">
                    <label className="form-label">
                     Email
                      <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      autoComplete="off"
                    
                      value={userData.email||""}
                      onChange={handleChange}
                      ref={userEmailRef}
                    />
                    {errors.email && (
                      <div className="error-message invalid-feedback">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer d-block">
              <CancelButton
                buttonText="Cancel"
                buttonIcon="bx bx-x"
                onClick={onClose}
                additionalClasses="btn-outline-secondary pull-left"
              />

              {buttonloading ? (
                <ButtonWithProgress
                  label="Update...."
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary pull-right"
        
                />
              ) : (
                <AddButton
                  label="Update"
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleUpdate}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAgentDialog;
