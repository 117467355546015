// ButtonWithProgress.js tick with add
import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
const ButtonWithProgress = ({ label, iconClass, buttonClass, onClick }) => {
  return (
    <button type="button" className={`btn ${buttonClass}`} onClick={onClick}>
      <i className={iconClass}></i> {label}  <CircularProgress
                          style={{
                            color: "white",
                            width: "17px",
                            height: "auto",
                          }}
                        />
    </button>
  );
};

export default ButtonWithProgress;