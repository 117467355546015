import React, { useState } from "react";
import EditButton from "../../reusable/EditIcon";

import DeleteButton from "../../reusable/DeleteIcon";
import EditProduct from "./EditProduct";
import DeleteDialog from "../../reusable/DeleteDialog";
import { Skeleton } from "@mui/material";
const formatNumber = (value) => {
  if (isNaN(value) || value === "") return "0.00";

  const parts = value.toString().split(".");

  if (parts.length === 2 && parts[1].length === 1) {
    parts[1] = "0" + parts[1];
  } else if (parts.length === 1) {
    parts.push("00");
  }

  return parts.join(".");
};

const ProductTable = ({
  data = [],
  handleUpdate,
  handleDelete,
  loading,
  buttonloading,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  console.log("new data", data);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((product) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      product.code.toLowerCase().startsWith(searchLower) ||
      product.title.toLowerCase().startsWith(searchLower) ||
      product.mrp.toString().startsWith(searchTerm) ||
      product.saleprice.toString().startsWith(searchTerm)
    );
  });
  //  const filteredData = data.filter((product) => {
  //   const searchLower = searchTerm.toLowerCase();
  //   return (
  //     product.code.toLowerCase().includes(searchLower) ||
  //     product.title.toLowerCase().includes(searchLower) ||
  //     product.mrp.toString().includes(searchTerm) ||
  //     product.saleprice.toString().includes(searchTerm)
  //   );
  // });
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
  };

  const openEditModal = (product) => {
    console.log("edit", product._id);
    setSelectedProduct(product);
    setIsDialogOpen(true);
  };
  const openDeleteDialog = (product) => {
    setSelectedProduct(product);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(selectedProduct._id);
    setIsDeleteDialogOpen(false);
  };
  const UpdateProduct = (updatedProductData) => {
    console.log("updates product", updatedProductData);
    console.log("updates", updatedProductData.productid);
    handleUpdate(updatedProductData);
    handleCloseDialog();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="row g-2">
              <div className="col-sm-8 card-header pt-4 ">
                <h6 className="mb-0">Product List</h6>
              </div>

              <div className="col-sm-4">
                <div className="input-group card-header py-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search product..."
                    fdprocessedid="vlw39b"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="input-group-text"
                    fdprocessedid="1vtzoc"
                    style={{ backgroundColor: "#ededff" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{
                  flex: "1",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <table className="table align-middle mb-0">
                  <thead
                    className="table-light"
                    style={{
                      background: "#f1f1f1",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <tr>
                      <th className="text-center">S No</th>
                      <th className="text-center">Product Code</th>
                      <th>Product Title</th>
                      <th className="text-center">MRP</th>

                      <th className="text-center">Selling Rate</th>

                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      [...Array(5)].map((_, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <Skeleton variant="text" width={40} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={500} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="center" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={300} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                        </tr>
                      ))
                    ) : Array.isArray(filteredData) &&
                      filteredData.length > 0 ? (
                      filteredData.map((product, index) => (
                        <tr key={`${product.productid}-${index}`}>
                          <td className="text-center">{index + 1}</td>

                          <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3 ",
                                backgroundColor: "rgb(13 110 253 / .11) ",
                              }}
                              className="badge rounded-pill "
                            >
                              {product.code}
                            </span>
                          </td>
                          <td className="text-left">
                            <h6 className="mb-0 fw-500">
                            {product.title.charAt(0).toUpperCase() + product.title.slice(1)}
                              </h6>
                          </td>

                          <td className="text-center ">
                            {/* {product.mrp} */}
                            {formatNumber(product.mrp)}
                          </td>
                          <td className="text-center ">
                            {/* {product.saleprice} */}
                            {formatNumber(product.saleprice)}
                          </td>
                          <td>
                            <div className="d-block order-actions text-center">
                              <EditButton
                                onEdit={() => openEditModal(product)}
                              />
                              <DeleteButton
                                onDelete={() => openDeleteDialog(product)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No Products available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditProduct
          productData={selectedProduct}
          onClose={handleCloseDialog}
          updateProduct={UpdateProduct}
          buttonloading={buttonloading}
        />
      )}

      {isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name="product"
        />
      )}
    </>
  );
};

export default ProductTable;
