import React, { useState } from "react";
import CancelButton from "./Buttons/CancelButton";
import DeleteButton from "./DeleteIcon";

const DeleteDialog = ({ onClose, onConfirm ,Name}) => {
  const [inputValue, setInputValue] = useState("");
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    setIsDeleteEnabled(value === "DELETE"); 
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-scrollable"
          onClick={stopPropagation}
          style={{
            display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
   
  }}
        >
          <div className="modal-content border-0">
            <div className="modal-header" style={{backgroundColor:'red'}}>
              <h6 className="modal-title" style={{color:'white'}}>Confirm Deletion</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
                style={{ color: "white" }}
              ></button>
            </div>
            <div className="modal-body">
            <p>
                Are you sure you want to delete this {Name}? This action cannot
                be undone.
              </p>
              <p>Please type "DELETE" to confirm deletion:</p>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="form-control"
                placeholder='Type "DELETE"'
              />
            </div>
            <div className="modal-footer">
            <CancelButton
                              buttonText="Cancel"
                              buttonIcon="bx bx-x"
                              onClick={onClose}
                              additionalClasses="btn-outline-secondary text-left"
                            />
             {/* <DeleteButton onConfirm={onConfirm} isDeleteEnabled={!isDeleteEnabled}/> */}
               <button
                type="button"
                onClick={onConfirm}
                className="btn btn-danger"
                disabled={!isDeleteEnabled}
              >
               <i className="bx bx-trash"  style={{fontSize:'20px'}}></i>  Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
