
import React, { useState,useRef } from 'react';

import logo from '../../styles/images/logo/logo.png';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '../../../utils/CookieUtiles';
import useErrorHandle from '../../error/ErrorHandling';
import CircularProgress from '@mui/material/CircularProgress';
import { verifiedToken } from '../../../utils/CreateApi';
import { useNavigate } from 'react-router-dom';


function Login({ setLoggedIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
  const handleError = useErrorHandle();
  const loginPasswordRef = useRef(null);
  const loginUsernamedRef = useRef(null);
  const navigate = useNavigate();
  const validateFields = () => {
    let valid = true;
    setEmailError('');
    setPasswordError('');
  
    if (!email) {
      setEmailError('This field must not be empty. Please enter the value.');
      valid = false; 
      loginUsernamedRef.current.focus();
    } else  if (!password){
     
        setPasswordError('This field must not be empty. Please enter the value.');
        valid = false; 
        loginPasswordRef.current.focus();
      }
      return valid; 
    }
  
  
  const handleLogin = async (e) => {
    console.log("login")
    console.log("url",apiUrl_Alagappa)
    e.preventDefault();  
    if (!validateFields()) {
      return; 
    }
    setLoading(true);
    try {
      
      const response = await fetch(`${apiUrl_Alagappa}auth-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'An error occurred');
        
        await handleError(response);
        return;
      }
      const data = await response.json();
      console.log('Login successful:', data);
      if (response.ok) {
		setAccessToken(data.results.accessToken);
        setRefreshToken(data.results.refreshToken);
        console.log("access",getAccessToken())
        console.log("refresh",getRefreshToken())
		setLoggedIn(true); 
    verifiedToken(navigate);
        // navigate('/dashboard'); 
      } else {
        setErrorMessage('Invalid username or password');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('An error occurred. Please try again.');
    }finally {
      setLoading(false);
     
    }
  };

  return (
    <div>
   
      <div className="wrapper">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="p-4">
                      <div className="mb-3 text-center">
                        <img src={logo} width="100" alt="" />
                      </div>
                      <div className="text-center mb-4">
                        <h5 className="" >Admin Portal</h5>
                        {/* <h5 className="" style={{fontSize:'17px'}}>Admin Portal</h5> */}
                        <p className="mb-0">Login to your account</p>
                      </div>
                      <div className="form-body">
                        <form className="row g-3" id="ownerLoginForm" onSubmit={handleLogin}>
                          <div className="col-12">
                            <label htmlFor="username" className="form-label">
                              Username <span className="text-danger">*</span>
                            </label>
                            
                            <input
                              type="text"
                              className="form-control LogInTindex"
                              id="username"
                              onChange={(e) => setEmail(e.target.value)}
                              autoComplete="off"
                              ref={loginUsernamedRef}
                            />   {emailError && <div className="error-message invalid-feedback" >{emailError}</div>}
                          </div>
                          {/* <span class="error-message invalid-feedback"></span> */}
                          <div className="col-12">
                            <label htmlFor="password" className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control LogInTindex"
                              id="password"
                              maxLength={8}
                              autoComplete="off"
                              ref={loginPasswordRef}
                              onChange={(e) => setPassword(e.target.value)}
                            />     {passwordError && <div className="error-message invalid-feedback">{passwordError}</div>}
                          </div>
                          
                          {/* {showToast && (
                            <div id="toast-container" className="toast-top-center" aria-live="polite" role="alert">
                              <div className="toast toast-warning" style={{ display: "block" }}>
                                <div className="toast-message">The requested resource could not be found. Please check your request and try again.</div>
                              </div>
                            </div>
                          )} */}
                         

                          {loading ? (<div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }}
                              >
                                 Processing.. <CircularProgress style={{color:'white', width:'17px',height:'auto'}}/>
                              </button>
                            </div>
                          </div>  ):( <div className="col-12 py-2">
                            <div className="d-grid">
                              <button
                                type="submit"  
                                className="btn btn-primary"
                                style={{ border: 'none', outline: 'none' }}
                              >
                                Login
                              </button>
                            </div>
                          </div>)}

                          {errorMessage && <div className="error-message invalid-feedback text-center">{errorMessage}</div>}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
