import React, { useEffect, useState } from "react";
import "../Products/product.css";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ProductTable from "./ProductTable";
import useErrorHandle from "../../error/ErrorHandling";

import AddProductDialog from "./AddProductDialog";
import AddButton from "../../reusable/Buttons/AddButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Product({ authToken }) {
  const [isProduct, setProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [shopCodeError, setShopCodeError] = useState();
  const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
  const [buttonloading, setButtonLoading] = useState(false);
  const handleError = useErrorHandle();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchProducts = async () => {
    setLoading(true);
    const url = `${apiUrl_Alagappa}list-product`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setProduct(data.results);
    } catch (error) {
      console.error("Error fetching shops:", error);
    }finally {
           
      setLoading(false);
      console.log("false load");
  }
  };

  useEffect(() => {
    console.log("Current Access Token:", authToken);
    fetchProducts();
  }, []);

  const addProduct = async (newProduct) => {
    setButtonLoading(true)
    if (
      !newProduct.code ||
      !newProduct.mrp ||
      !newProduct.saleprice ||
      !newProduct.title
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("All fields are required.");
      setSnackbarOpen(true);
      return;
    }

    const url = `${apiUrl_Alagappa}add-product`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        code: newProduct.code,
        mrp: newProduct.mrp,
        saleprice: newProduct.saleprice,
        title: newProduct.title,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (!response.ok) {
              
        if (data.code === "DUPLICATE_ENTRY") {
            
           
            setShopCodeError("Shop code already exists. Please provide a different code.")
            console.log("shoperror success")
            
        } else {
          
            
            await handleError(response);
        }
        setButtonLoading(false);
        return;
    }
      setProduct((prevShops) => {
        console.log('Previous Shops:', prevShops);
        return [...(prevShops || []), data.results]; 
    });
    

      setSnackbarSeverity("success");
      setSnackbarMessage("Product added successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false)
      closeModal();
    } catch (error) {
      console.error("Error adding product:", error);
      setButtonLoading(false)
      setSnackbarSeverity("error");
      setSnackbarMessage("Error adding product.");
      setSnackbarOpen(true);
    }
  };

  const handleUpdateProduct = async (updatedProduct) => {
    setButtonLoading(true)
    if (
        !updatedProduct.code ||
        !updatedProduct.mrp ||
        !updatedProduct.saleprice ||
        !updatedProduct.title
      ) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Please fill all fields.");
        setSnackbarOpen(true);
        return;
      }
  
    console.log("updateData",updatedProduct)
    if (!updatedProduct.productid) {
      console.error("Error: product is required but not provided.");
      return;
    }
   
    console.log("Product id:", updatedProduct.productid);
    const url = `${apiUrl_Alagappa}update-product`;

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        productid: updatedProduct.productid,
        code: updatedProduct.code,
        mrp: updatedProduct.mrp,
        saleprice: updatedProduct.saleprice,
        title: updatedProduct.title,
      }),
    };

    try {
      const response = await fetch(url, options);
  ;

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response:", errorData);
        await handleError(response);
      }

      const data = await response.json();
     

  
      setProduct((prevProducts) => {
        return prevProducts.map((product) =>
          product._id === data.results._id ? data.results : product
        );
      });
      
  
 
      setSnackbarSeverity("success");
      setSnackbarMessage("Product updated successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false)
    } catch (error) {
      console.error("Error updating product:", error);
      setButtonLoading(false)
      setSnackbarSeverity("error");
      setSnackbarMessage("Error updating product.");
      setSnackbarOpen(true);
    }
  };






   const handleDeleteProduct = async (productId) => {
    console.log("Deleting product with ID:", productId);
    const url = `${apiUrl_Alagappa}delete-product`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ productid: productId }), 
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (!response.ok) {
            console.error("Failed to delete product:", data.message || "Unknown error");
            setSnackbarSeverity("error");
            setSnackbarMessage("Error deleting product: " + (data.message || "Unknown error"));
            setSnackbarOpen(true);
            await handleError(response);
            return;
        }

        setProduct((prevProducts) => {
            const updatedProducts = prevProducts.filter((product) => product._id !== productId);
            console.log("Updated Products after deletion:", updatedProducts);
            return updatedProducts;
        });

        setSnackbarSeverity("success");
        setSnackbarMessage("Product deleted successfully!");
        setSnackbarOpen(true);
    } catch (error) {
        console.error("Error deleting product:", error);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error deleting product.");
        setSnackbarOpen(true);
    }
};


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3"></div>
        <div className="ms-auto">
          <div className="btn-group">
          <AddButton label=" Add Product"
                      iconClass="bx bx-plus"
                      buttonClass="btn btn-primary text-right"
                      onClick={openModal}/>
           

          

          </div>
        </div>
      </div>

      {isModalOpen && (
        <AddProductDialog onclose={closeModal} addProduct={addProduct} buttonloading={buttonloading}  setShopCodeError={setShopCodeError} shopCodeError={shopCodeError}/>
      )}

      <ProductTable data={isProduct} handleUpdate={handleUpdateProduct} handleDelete={handleDeleteProduct} loading={loading} buttonloading={buttonloading}/>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Product;
