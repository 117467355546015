// utils/CookieApi.js


import { getRefreshToken,setAccessToken, getAccessToken,removeRefreshToken, removeAccessToken, setUserEmail, getUserEmail, setUserRoll, getUserRoll} from "./CookieUtiles";
const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;

export const createToken = async (navigate,setLoggedIn) => {
    console.log("create token");
    removeAccessToken();
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            setLoggedIn(false)
            navigate('/login');
            console.log("log")
            return false;
        }

        const response = await fetch(`${apiUrl_Alagappa}create-account-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`,
            },
        });
console.log("referesh",refreshToken)
        if (response.status === 401) {
            removeRefreshToken();
            setLoggedIn(false)
            console.log("401")
            navigate('/login');
            return false;
        }

        const data = await response.json();

   
        if (response.ok && data.results && data.results.accessToken) {
            setAccessToken(data.results.accessToken);
           
            verifiedToken(navigate);
            return true;
        } else {
            console.log("hi")
            setLoggedIn(false)
            navigate('/login');
            return false;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        setLoggedIn(false)
        navigate('/login');
        return false;
    }
};

export const verifiedToken = async (navigate) => {
    console.log("Auth token verify");
    try {
        const accessToken = getAccessToken();
        console.log("verify auth",accessToken)
        const response = await fetch(`${apiUrl_Alagappa}auth-account-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
            return;
        }

        const data = await response.json();
        if (response.ok && data.status && data.code === "VERIFIED") {
            console.log("User details:", data.results);
            setUserEmail(data.results.email);  
            setUserRoll(data.results.role);  
            
            console.log("email from cookie:", getUserEmail() ,getUserRoll()); 
        } else {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
        }
    } catch (error) {
        console.error("Error authenticating token:", error);
        console.log("Error authenticating token:", error);
        navigate('/login');
    }
};
