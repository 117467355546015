
import React from 'react';

function CancelButton({ buttonText, buttonIcon, onClick, additionalClasses }) {
  return (
    <button
      type="button"
      className={`btn shadow-none ${additionalClasses}`}
      onClick={onClick}
    >
      <i className={buttonIcon} ></i> {buttonText}
    </button>
  );
}

export default CancelButton;