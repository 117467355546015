import React, { useEffect, useState } from "react";
import "../orders/order.css";

import { getAccessToken } from "../../../utils/CookieUtiles";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import useErrorHandle from "../../error/ErrorHandling";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ViewOrders({ order, onClose, setOrders }) {
  const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
  const [IsStatus, setStatus] = useState("CREATED");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isStateLoading, setStateloading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const authToken = getAccessToken();
  useEffect(() => {
    if (order) {
      setStatus(order.status);
      console.log("useEffect triggered, order.status:", order.status);
    }
  }, [order]);

  const handleError = useErrorHandle();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "bg-label-success1";
      case "created":
        return "bg-label-info";
      case "accepted":
        return "bg-label-blue";
      default:
        return "bg-label-default";
    }
  };
  const formatOrderDate = (dateString) => {
    const date = new Date(dateString);

    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(" ", "-")
      .replace(" ", "-")
      .toUpperCase();
  };

  const handleStatusUpdate = async (event) => {
    setStateloading(true);
    const newStatus = event.target.value;
    setStatus(newStatus);

    try {
      const response = await fetch(`${apiUrl_Alagappa}/update-order-status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          shopid: order.shopid._id,
          orderid: order._id,
          status: newStatus,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log("Failed to update Status", errorData);
        await handleError(response);
      }

      const data = await response.json();
      if (response.ok) {
        setStatus(data.results.status);
        setOrders((prevData) =>
          prevData.filter((ord) => ord._id !== order._id)
        );
        // setOrders((prevData) =>
        //   prevData.map((ord) =>
        //     ord._id === order._id
        //       ? { ...ord, status: data.results.status }
        //       : ord
        //   )
        // );
        setSnackbarMessage("Status updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setStateloading(false);
      }

      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating Status:", error);
      setSnackbarMessage("Error updating Status");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setStateloading(false);
    }
  };

  return (
    <div>
      <div className="card">
        <div>
          <div className="modal-content">
            <div
              className="modal-header p-3"
              style={{ borderBottom: "2px solid #e9ecff" }}
            >
              <h6 className="modal-title">View Order</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close fs-16"
              ></button>
            </div>
            {order ? (
              <div className="modal-body mb-2 mheight">
                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small> Order Info</small>
                    </h6>
                  </div>
                  <div className="card-body p-1 box-border">
                    <table className="table table-borderless table-sm mb-0">
                      <tbody>
                        <tr>
                          <td className="text-muted">Order Id</td>
                          <td>{order._id}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Date</td>
                          <td> {formatOrderDate(order.orderdate)}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Status</td>
                          <td className="text-muted">
                            <td className="text-muted">
                              <span
                                className={`badge rounded-pill ${getColor(
                                  IsStatus
                                )}`}
                              >
                                {IsStatus || "N/A"}
                              </span>
                            </td>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small>Order Details</small>
                    </h6>
                  </div>
                  <div className="card-body p-1 box-border">
                    <table className="table table-bordered align-items-center mb-0">
                      <thead>
                        <tr className="">
                          <th class="text-left text-dark bg-light">Title</th>
                          <th class="text-center text-dark bg-light">Qty</th>
                          <th class="text-center text-dark bg-light">Price</th>

                          <th class="text-center text-dark bg-light">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.cart_data && order.cart_data.length > 0 ? (
                          order.cart_data.map((item, index) => (
                            <tr>
                              <td class="text-left text-muted">{item.title}</td>
                              <td class="text-center text-muted">
                                {item.quantity}
                              </td>
                              <td class="text-center text-muted">
                                {item.saleprice}
                              </td>

                              <td class="text-center text-muted">
                                {(item.quantity * item.saleprice || 0).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p className="text-center">Your cart is empty.</p>
                        )}
                        <tr>
                          <td className="text-right" colspan="3">
                            <h6 class="my-1">Grand Total</h6>
                          </td>
                          <td class=" bg-light">
                            <h5 class="mb-0 fw-600">
                              ₹{order.grandtotal.toFixed(2)}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small>Update Order Status</small>
                    </h6>
                  </div>
                  <div className="card-body p-2 box-border">
                  <div className="row g-1">
  {isStateLoading ? (
    <div className="col text-center">
      
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    ["CREATED", "ACCEPTED", "COMPLETED", "CANCELLED"].map((status) => {
      const statusClasses = {
        CREATED: "btn-info",
        ACCEPTED: "btn-primary",
        COMPLETED: "btn-success",
        CANCELLED: "btn-danger",
      };

      return (
        <div className="col" key={status}>
          <button
            type="button"
            className={`btn btn-sm rounded-2 ${
              IsStatus === status ? statusClasses[status] : "btn-light"
            }`}
            value={status}
            onClick={handleStatusUpdate}
          >
            {status.charAt(0) + status.slice(1).toLowerCase()}
          </button>
        </div>
      );
    })
  )}
</div>

                  </div>
                </div>

                {order?.shopid?.address && (
                  <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                    <div className="box-header bg-light-theme px-2 py-1">
                      <h6 className="modal-title bgtxt text-primary">
                        <small>Shop Address</small>
                      </h6>
                    </div>
                    <div className="card-body p-1 box-border">
                      <table className="table table-borderless table-sm mb-0">
                        <tbody>
                          <tr>
                            <td className="text-muted"> Name:</td>
                            <td>{order.shopid.name}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Address:</td>
                            <td>
                              {order.shopid.address.addressline1},
                              {order.shopid.address.addressline2},
                              {order.shopid.address.city},
                              {order.shopid.address.state} -
                              {order.shopid.address.pincode}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {order?.shopid?.billing_address && (
                  <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                    <div className="box-header bg-light-theme px-2 py-1">
                      <h6 className="modal-title bgtxt text-primary">
                        <small> Biling Address</small>
                      </h6>
                    </div>
                    <div className="card-body p-1 box-border">
                      <table className="table table-borderless table-sm mb-0">
                        <tbody>
                          <td>
                            {order.shopid.billing_address.addressline1},
                            {order.shopid.billing_address.addressline2},
                            {order.shopid.billing_address.city},
                            {order.shopid.billing_address.state} -
                            {order.shopid.billing_address.pincode}
                          </td>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center p-2">
                Select order to view deatails
              </div>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ViewOrders;
