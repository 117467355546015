import React, { useEffect, useState } from 'react';
import '../home-page/shop.css';
import AddShopDialog from './AddShopDialog';
import ShopTable from './ShopTable';
import useErrorHandle from '../error/ErrorHandling';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddButton from '../reusable/Buttons/AddButton';
import { removeShopId, removeShopNames, setShopId, setShopNames } from '../../utils/CookieUtiles';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Shops({ authToken }) {
    const [isshops, setShops] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonloading, setButtonLoading] = useState(false);
    const [shopCodeError, setShopCodeError] = useState();
    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const handleError = useErrorHandle();
    const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchShops = async () => {
        removeShopId();
        removeShopNames();
        setLoading(true);
        
        const url = `${apiUrl_Alagappa}list-shop`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                await handleError(response);
            }
            const data = await response.json();
            console.log(data);
            setShops(data.results);
            const shopIds = data.results.map(shop => shop._id);
            const shopNames=data.results.map(shop => shop.name);
            setShopId(shopIds);
            setShopNames(shopNames);
         
        } catch (error) {
            console.error('Error fetching shops:', error);
        }finally {
           
            setLoading(false);
            console.log("false load");
        }
    };

    
    useEffect(() => {
        console.log("Current Access Token:", authToken); 
        fetchShops();
        
    }, []);


    const addShop = async (newShop) => {
        setButtonLoading(true);
        const url = `${apiUrl_Alagappa}add-shop`;
    
        const payload = {
            name: newShop.name,
            shopcode: newShop.shopcode,
            password: newShop.password,
            registerno: newShop.registerno,
            address: {
                addressline1: newShop.address.addressline1,
                addressline2: newShop.address.addressline2,
                city: newShop.address.city,
                state: newShop.address.state,
                pincode: newShop.address.pincode,
            },
            billing_address: {
                addressline1: newShop.billing_address.addressline1,
                addressline2: newShop.billing_address.addressline2,
                city: newShop.billing_address.city,
                state: newShop.billing_address.state,
                pincode: newShop.billing_address.pincode,
            },
            gstnumber: newShop.gstnumber,
        };
    
        if (newShop.phone) {
            payload.phone = newShop.phone;
        }
    
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(payload),
        };
    
        try {
            const response = await fetch(url, options);
    
            const data = await response.json();
            if (!response.ok) {
              
                if (data.code === "DUPLICATE_ENTRY") {
                    
                    setErrorMessage(data.message);
                    setShopCodeError("Shop code already exists. Please provide a different code.")
                    console.log("shoperror success")
                    
                } else {
                  
                    setErrorMessage(data.message || 'An error occurred');
                    await handleError(response);
                }
                setButtonLoading(false);
                return;
            }
            setShops((prevShops) => {
                console.log('Previous Shops:', prevShops);
                return [...(prevShops || []), data.results]; 
            });
           
            // const shopWithId = { ...data.results, id: (isshops.length + 1).toString() };
            // setShops([...isshops, shopWithId]);
            setSnackbarSeverity('success');
            setSnackbarMessage("Shop added successfully!");
            setSnackbarOpen(true);
            setButtonLoading(false);
            closeModal();
        } catch (error) {
            console.error('Error adding shop:', error);
            setSnackbarSeverity('error');
            setErrorMessage('An error occurred. Please try again.');
            setButtonLoading(false);
        }
    };
    
    const updateShop = async (updatedShop) => {
        setButtonLoading(true);
        if (!updatedShop._id) {
            console.error("Error: shopid is required but not provided.");
            return;
        }

        console.log("Shop id:", updatedShop._id);
        const url = `${apiUrl_Alagappa}update-shop`;

        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                shopid: updatedShop._id,
                name: updatedShop.name || undefined,
                shopcode: updatedShop.shopcode || undefined,
                password: updatedShop.password || undefined,
                registerno: updatedShop.registerno || undefined,
                phone: updatedShop.phone || undefined,
                address: {
                    addressline1: updatedShop.address?.addressline1 || undefined,
                    addressline2: updatedShop.address?.addressline2 || undefined,
                    city: updatedShop.address?.city || undefined,
                    state: updatedShop.address?.state || undefined,
                    pincode: updatedShop.address?.pincode || undefined,
                },
                billing_address: {
                    addressline1: updatedShop.billing_address?.addressline1 || undefined,
                    addressline2: updatedShop.billing_address?.addressline2 || undefined,
                    city: updatedShop.billing_address?.city || undefined,
                    state: updatedShop.billing_address?.state || undefined,
                    pincode: updatedShop.billing_address?.pincode || undefined,
                },
                gstnumber: updatedShop.gstnumber || undefined,
            }),
        };

        try {
            const response = await fetch(url, options);
            console.log("Response:", response);

            if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'An error occurred');
                await handleError(response);
            }

            const data = await response.json();
          

            setShops((prevShops) => {
                return prevShops.map((shop) =>
                    shop._id === data.results._id ? data.results : shop
                );
            });
       
            setSnackbarSeverity('success');
            setSnackbarMessage("Shop updated successfully!");
            setSnackbarOpen(true);
            setButtonLoading(false);
          
        } catch (error) {
            console.error('Error updating shop:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Error updating shop.');
            setSnackbarOpen(true);
            setButtonLoading(false);
        }
    };
    const handleDeleteShop = async (shopId) => {
        console.log("deleteid",shopId)
        const url = `${apiUrl_Alagappa}delete-shop`;
        const options = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ shopid : shopId }),
        };
      
        try {
          const response = await fetch(url, options);
          if (!response.ok) {
            await handleError(response);
            return;
          }
      
     
          setShops((prevShops) =>
            prevShops.filter((shop) => shop.shopId !== shopId)
          );
          fetchShops();
          setSnackbarSeverity("success");
          setSnackbarMessage("Product deleted successfully!");
          setSnackbarOpen(true);
        } catch (error) {
          console.error("Error deleting product:", error);
          setSnackbarSeverity("error");
          setSnackbarMessage("Error deleting product.");
          setSnackbarOpen(true);
        }
      };
      
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    useEffect(() => {
        if (showToast) {
          const timer = setTimeout(() => {
            setShowToast(false);
          }, 7000);
    
         
          return () => clearTimeout(timer);
        }
      }, [showToast]);
    return (
        <div>
            <div className="page-breadcrumb d-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3"></div>
                <div className="ms-auto">
                    <div className="btn-group">

                    <AddButton label="Add Shop"
                      iconClass="bx bx-plus"
                      buttonClass="btn btn-primary text-right"
                      onClick={openModal}/>
                        


                    </div>
                </div>
            </div>

            {isModalOpen && (
                <AddShopDialog onclose={closeModal} addShop={addShop} loading={buttonloading} errorMessage={errorMessage} setShopCodeError={setShopCodeError} shopCodeError={shopCodeError}/>
            )}

            <ShopTable data={isshops} handleUpdate={updateShop} loading={loading}  updateload={buttonloading}
            handleDelete={handleDeleteShop}
             errorMessage={errorMessage}/>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Shops;
