
import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import NavBar from "./components/layout/header/NavBar";
import Login from "./components/specific/login-page/Login";
import Dashborad from "./components/specific/dashboard/Dashborad";
import SideMenu from "./components/layout/menu/SideMenu";
import Shops from "./components/home-page/Shops";
import { getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken } from "./utils/CookieUtiles";
import { createToken } from "./utils/CreateApi";

import Product from "./components/home-page/Products/Product";
import Orders from "./components/home-page/orders/Orders";
import DynamicTitleAndIcon from "./components/specific/dashboard/DynamicTitle";
import Agent from "./components/home-page/agent/Agent";


function App() {
 
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
const [loggedIn, setLoggedIn] = useState(() => {

  const token = getRefreshToken();
  return !!token; 
});

const [authToken, setAuthToken] = useState(getAccessToken()); 
const handleLogin = () => {
  setLoggedIn(true);
  navigate("/")
 
};



useEffect(() => {
  console.log("Access Token:", getAccessToken());
console.log("Refresh Token:", getRefreshToken());
  const token = getAccessToken(); 
  if(token){
    setAuthToken(token); 
  }
  else if(!token){
 createToken(navigate,setLoggedIn);
const token = getAccessToken();
setAuthToken(token); 
console.log("authToken", authToken); 
}

  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    console.log("Refresh token is empty")
    handleLogout();
  }

  console.log("authToken", authToken); 
}, [authToken,getAccessToken()]);


const handleLogout = () => {
  navigate('/login');
  setLoggedIn(false);
  removeAccessToken();
  removeRefreshToken();

};

const handleResize = () => {
  if (window.innerWidth >= 1280) {
    setDrawerOpen(true);
  } else {
    setDrawerOpen(false); 
  }
};

useEffect(() => {
  
  handleResize();
  window.addEventListener("resize", handleResize);
  
 
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);
  

  return (
    <div>
     <DynamicTitleAndIcon />
      {!loggedIn ? (
        <Login setLoggedIn={handleLogin} to="/login"/>
      ) : (
     
          <div className="app">
          
            <NavBar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} setLoggedIn={handleLogout} />  
             <div className="main">
            {drawerOpen && <SideMenu open={drawerOpen} setOpen={setDrawerOpen} />}
            <div className={`content ${drawerOpen ? "content-open" : "content-closed"}`}>
              <Routes>
               
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashborad />} /> 
                <Route path="/shop" element={<Shops  authToken={authToken}/>} /> 
                <Route path="/product" element={<Product  authToken={authToken}/>} /> 
                <Route path="/agent" element={<Agent  authToken={authToken}/>} /> 
                <Route path="/order" element={<Orders  authToken={authToken}/>} /> 
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;