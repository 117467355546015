
import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get('pharmacy_accessToken');
};

export const getRefreshToken = () => {
  return Cookies.get('pharmacy_refreshToken');
};

export const setAccessToken = (token) => {
  Cookies.set('pharmacy_accessToken', token, {
    secure: true,
    sameSite: 'Strict',
    path: '/'
  });
};

export const setRefreshToken = (token) => {
  Cookies.set('pharmacy_refreshToken', token, { 
    expires: 30, 
    secure: true,
    sameSite: 'Strict',
    path: '/' 
  });
};

export const removeAccessToken = () => {
  Cookies.remove('pharmacy_accessToken', { path: '/' });
};

export const removeRefreshToken = () => {
  Cookies.remove('pharmacy_refreshToken', { path: '/' });
};

export const setUserEmail = (email) => {
  Cookies.set('user_email', email, {
      expires: 30,
      secure: true,
      sameSite: 'Strict',
      path: '/'
  });
};

export const getUserEmail = () => {
  return Cookies.get('user_email');
};


export const removeUserEmail = () => {
  Cookies.remove('user_email', { path: '/' });
};
export const getUserRoll = () => {
  return Cookies.get('user_roll');
};


export const removeUserRoll = () => {
  Cookies.remove('user_roll', { path: '/' });
};



export const setUserRoll = (roll) => {
  Cookies.set('user_roll', roll, {
      expires: 30,
      secure: true,
      sameSite: 'Strict',
      path: '/'
  });
};




export const removeShopId = () => {
  Cookies.remove('shop_id', { path: '/' });
};



export const setShopId = (shopId) => {

  const existingShopIds = Cookies.get('shop_id');
  let shopIdsArray = existingShopIds ? existingShopIds.split(',') : [];

  if (!shopIdsArray.includes(shopId)) {
    shopIdsArray.push(shopId);
  }


  Cookies.set('shop_id', shopIdsArray.join(','), {
    expires: 30,
    secure: true,
    sameSite: 'Strict',
    path: '/',
  });
};


export const getShopId = () => {
  const existingShopIds = Cookies.get('shop_id');
  return existingShopIds ? existingShopIds.split(',') : [];
};





export const setShopNames = (shopName) => {

  const existingShopNames = Cookies.get('shop_name');
  let shopNamesArray = existingShopNames ? existingShopNames.split(',') : [];

  if (!shopNamesArray.includes(shopName)) {
    shopNamesArray.push(shopName);
  }


  Cookies.set('shop_name', shopNamesArray.join(','), {
    expires: 30,
    secure: true,
    sameSite: 'Strict',
    path: '/',
  });
};



export const getShopNames = () => {
 
  const existingShopNames = Cookies.get('shop_name');
  return existingShopNames ? Array.from(new Set(existingShopNames.split(','))) : [];
};

export const removeShopNames = () => {
  Cookies.remove('shop_name', { path: '/' });
};
