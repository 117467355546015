import React, { useEffect, useState, useRef } from "react";
import "../home-page/shop.css";

import CancelButton from "../reusable/Buttons/CancelButton";
import AddButton from "../reusable/Buttons/AddButton";
import ButtonWithProgress from "../reusable/Buttons/ButtonWithProgress";
function EditShopDialog({ onClose, updateShop, shop, updateload }) {
  const [shopData, setShopData] = useState({
    name: "",
    phone: "",
    gstnumber: "",
    registerno: "",
    email: "",
    address: {
      addressline1: "",
      addressline2: "",
      city: "",
      state: "",
      pincode: "",
    },
    billing_address: {
      addressline1: "",
      addressline2: "",
      city: "",
      state: "",
      pincode: "",
    },
    shopcode: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const shopNameRef = useRef(null);
  const shopCodeRef = useRef(null);
  const shopPasswordRef = useRef(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep Islands",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Other Territory",
  ];
  const sortedStates = states.sort();
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  
    shopPasswordRef.current.focus();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue;
    if (
      name === "gstnumber" ||
      name === "shopcode" ||
      name === "registerno" ||
      name === "password"
    ) {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }

    setShopData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value.trimStart().replace(/\s+/g, " ");

    setShopData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: trimmedValue,
      },
    }));
  };

  const handleBillingAddressChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value.trimStart().replace(/\s+/g, " ");

    setShopData((prevData) => ({
      ...prevData,
      billing_address: {
        ...prevData.billing_address,
        [name]: trimmedValue,
      },
    }));
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!shopData.name) {
      newErrors.name = "Shop Name is required.";
      valid = false;
      shopNameRef.current.focus();
    } else if (shopData.name.length > 1000) {
      newErrors.name = "Shop Name must not exceed 1000 characters.";
      valid = false;
      shopNameRef.current.focus();
    } else if (!shopData.shopcode) {
      newErrors.shopcode = "Shop Code is required.";
      valid = false;
      shopCodeRef.current.focus();
    } else if (!shopData.password) {
      newErrors.password = "Password is required.";
      valid = false;
      shopPasswordRef.current.focus();
    }

    if (shopData.phone) {
      if (shopData.phone.length !== 10) {
        newErrors.phone = "Phone Number must be 10 digits.";
        valid = false;
      } else if (!/^\d{10}$/.test(shopData.phone)) {
        newErrors.phone = "Phone Number must be numeric.";
        valid = false;
      }
    } else {
      newErrors.phone = "";
    }

    if (shopData.gstnumber && shopData.gstnumber.length > 1000) {
      newErrors.gstnumber = "GST Number must not exceed 1000 characters.";
      valid = false;
    }
    const { address = {}, billing_address = {} } = shopData;

    // Validate address fields
    if (shopData.addressline1 && address.addressline1.length > 2000) {
      newErrors.addressline1 =
        "Address Line 1 must not exceed 2000 characters.";
      valid = false;
    } else if (address.addressline2 && address.addressline2.length > 2000) {
      newErrors.addressline2 =
        "Address Line 2 must not exceed 2000 characters.";
      valid = false;
    } else if (address.city && address.city.length > 1000) {
      newErrors.city = "City must not exceed 1000 characters.";
      valid = false;
    } else if (address.state && address.state.length > 1000) {
      newErrors.state = "State must not exceed 1000 characters.";
      valid = false;
    } else if (address.pincode && address.pincode.length < 6) {
      newErrors.pincode = "Pincode must be at least 6 characters long.";
      valid = false;
    } else if (address.pincode && isNaN(address.pincode)) {
      newErrors.pincode = "Pincode must be a valid number.";
      valid = false;
    }

    // Validate billing address fields
    if (
      billing_address.addressline1 &&
      billing_address.addressline1.length > 2000
    ) {
      newErrors.billingAddressLine1 =
        "Billing Address Line 1 must not exceed 2000 characters.";
      valid = false;
    } else if (
      billing_address.addressline2 &&
      billing_address.addressline2.length > 2000
    ) {
      newErrors.billingAddressLine2 =
        "Billing Address Line 2 must not exceed 2000 characters.";
      valid = false;
    } else if (billing_address.city && billing_address.city.length > 1000) {
      newErrors.billingCity = "Billing City must not exceed 1000 characters.";
      valid = false;
    } else if (billing_address.state && billing_address.state.length > 1000) {
      newErrors.billingState = "Billing State must not exceed 1000 characters.";
      valid = false;
    } else if (billing_address.pincode && billing_address.pincode.length < 6) {
      newErrors.billingPincode = "Pincode must be at least 6 characters long.";
      valid = false;
    } else if (billing_address.pincode && isNaN(billing_address.pincode)) {
      newErrors.billingPincode = "Pincode must be a valid number.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleUpdate = () => {
    console.log("add", shopData);
    if (!validateFields()) {
      return;
    }

    updateShop(shopData);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    if (shop) {
      setShopData(shop);
    }
  }, [shop]);
  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          onClick={stopPropagation}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Edit Shop</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Shop Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="off"
                      value={shopData.name}
                      onChange={handleChange}
                      ref={shopNameRef}
                    />
                    {errors.name && (
                      <div className="error-message invalid-feedback">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      Shop Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="shopcode"
                      value={shopData.shopcode}
                      onChange={handleChange}
                      ref={shopCodeRef}
                    />
                    {errors.shopcode && (
                      <div className="error-message invalid-feedback">
                        {errors.shopcode}
                      </div>
                    )}

                    {/* {shopCodeError && <div className="error-message invalid-feedback">{shopCodeError}</div>}  */}
                  </div>
                  <div className="col-lg-6">
             <div style={{display:'flex', justifyContent:'space-between'}}>
          <label className="form-label">
                      Password<span className="text-danger">*</span>
                    </label>
                    <span  onClick={togglePasswordVisibility} style={{ cursor: 'pointer',paddingRight:'5px' }}>
          {isPasswordVisible ? <i class="bx bx-show" style={{color:'#4050b5',fontSize:'20px'}}></i> : <i class="bx bx-hide" style={{fontSize:'20px'}}></i>}
        </span>
        </div>
                    
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      className="form-control"
                      autoComplete="off"
                      name="password"
                      value={shopData.password}
                      onChange={handleChange}
                      ref={shopPasswordRef}
                    />
                    {errors.password && (
                      <div className="error-message invalid-feedback">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <>
                      <label className="form-label">Phone Number</label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          id="basic-addon41"
                          style={{
                            backgroundColor: "#ededff",
                            color: "#242c6d !important",
                          }}
                        >
                          +91
                        </span>
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control"
                          fdprocessedid="v93442"
                          autoComplete="off"
                          name="phone"
                          value={shopData.phone}
                          onChange={(e) => {
                            const value = e.target.value;

                            if (/^\d{0,10}$/.test(value)) {
                              handleChange(e);
                            }
                          }}
                        />
                        {errors.phone && (
                          <div className="error-message invalid-feedback">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">GST Number</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="gstnumber"
                      value={shopData.gstnumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="registerno"
                      value={shopData.registerno}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12">
                    <div className="accordion-item">
                      <h6 className="accordion-header" id="flush-headingOne">
                        Address (Optional)
                      </h6>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                        style={{}}
                      >
                        <div className="accordion-body p-3">
                          <div className="row g-3">
                            <div className="col-lg-6">
                              <label for="bsValidation1" class="form-label">
                                Address Line 1
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={handleAddressChange}
                                maxLength={2000}
                                autoComplete="off"
                                name="addressline1"
                                value={shopData.address?.addressline1 || ""}
                                id="bsValidation1"
                              />
                              {errors.addressline1 && (
                                <div className="error-message invalid-feedback">
                                  {errors.addressline1}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                            <div class="col-lg-6">
                              <label for="bsValidation1" className="form-label">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={handleAddressChange}
                                maxLength={2000}
                                autoComplete="off"
                                name="addressline2"
                                value={shopData.address?.addressline2 || ""}
                                id="bsValidation1"
                              />
                              {errors.addressline2 && (
                                <div className="error-message invalid-feedback">
                                  {errors.addressline2}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                            <div className="col-lg-4">
                              <label for="bsValidation1" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                onChange={handleAddressChange}
                                name="city"
                                value={shopData.address?.city || ""}
                                id="bsValidation1"
                              />
                              {errors.city && (
                                <div className="error-message invalid-feedback">
                                  {errors.city}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>

                            <div className="col-lg-4">
                              <label htmlFor="state" className="form-label">
                                State
                              </label>
                              <select
                               className="form-select"
                                id="state"
                                name="state"
                                value={shopData.address?.state || ""}
                                onChange={handleAddressChange}
                              >
                                <option value="">- - select - -</option>
                                {sortedStates.map((state, index) => (
                                  <option key={index} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>

                              {errors.state && (
                                <div className="error-message invalid-feedback">
                                  {errors.state}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <label for="bsValidation1" className="form-label">
                                Pincode
                              </label>
                              <input
                                type="text"
                                maxLength={6}
                                className="form-control"
                                autoComplete="off"
                                onChange={handleAddressChange}
                                name="pincode"
                                value={shopData.address?.pincode || ""}
                                id="bsValidation1"
                              />
                              {errors.pincode && (
                                <div className="error-message invalid-feedback">
                                  {errors.pincode}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="accordion-item">
                      <h6 className="accordion-header" id="flush-headingOne">
                        Billing Address (Optional)
                      </h6>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                        style={{}}
                      >
                        <div className="accordion-body p-3">
                          <div className="row g-3">
                            <div className="col-lg-6">
                              <label for="bsValidation1" class="form-label">
                                Address Line 1
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="bsValidation1"
                                autoComplete="off"
                                onChange={handleBillingAddressChange}
                                maxLength={2000}
                                name="addressline1"
                                value={
                                  shopData.billing_address?.addressline1 || ""
                                }
                              />
                              {errors.billingAddressLine1 && (
                                <div className="error-message invalid-feedback">
                                  {errors.billingAddressLine1}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                            <div class="col-lg-6">
                              <label for="bsValidation1" className="form-label">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                onChange={handleBillingAddressChange}
                                maxLength={2000}
                                name="addressline2"
                                value={
                                  shopData.billing_address?.addressline2 || ""
                                }
                                id="bsValidation1"
                              />
                              {errors.billingAddressLine2 && (
                                <div className="error-message invalid-feedback">
                                  {errors.billingAddressLine2}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                            <div className="col-lg-4">
                              <label for="bsValidation1" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                autoComplete="off"
                                onChange={handleBillingAddressChange}
                                name="city"
                                value={shopData.billing_address?.city || ""}
                                className="form-control"
                                id="bsValidation1"
                              />
                              {errors.billingCity && (
                                <div className="error-message invalid-feedback">
                                  {errors.billingCity}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>

                            <div className="col-lg-4">
                              <label htmlFor="state" className="form-label">
                                State
                              </label>
                              <select
                                className="form-select"
                                id="state"
                                name="state"
                                value={shopData.billing_address?.state || ""}
                                onChange={handleBillingAddressChange}
                              >
                                <option value="">- - select - -</option>
                                {sortedStates.map((state, index) => (
                                  <option key={index} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>

                              {errors.state && (
                                <div className="error-message invalid-feedback">
                                  {errors.state}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                            <div className="col-lg-4">
                              <label for="bsValidation1" className="form-label">
                                Pincode
                              </label>
                              <input
                                type="text"
                                maxLength={6}
                                onChange={handleBillingAddressChange}
                                autoComplete="off"
                                name="pincode"
                                value={shopData.billing_address?.pincode || ""}
                                className="form-control"
                                id="bsValidation1"
                              />
                              {errors.billingPincode && (
                                <div className="error-message invalid-feedback">
                                  {errors.billingPincode}
                                </div>
                              )}
                              <div className="valid-feedback"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer d-block">
              <CancelButton
                buttonText="Cancel"
                buttonIcon="bx bx-x"
                onClick={onClose}
                additionalClasses="btn-outline-secondary pull-left"
              />

              {updateload ? (
                <ButtonWithProgress
                  label="Update...."
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleUpdate}
                />
              ) : (
                <AddButton
                  label="Update"
                  iconClass="bx bx-check"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleUpdate}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditShopDialog;
