import React from 'react'

function Dashborad() {
  return (
    <div>
      <h6 className="mb-0">Dashborad</h6>
    </div>
  )
}

export default Dashborad
