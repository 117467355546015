import React, { useState } from "react";
import EditButton from "../reusable/EditIcon";
import EditShopDialog from "./EditShopModel";
import Skeleton from "@mui/material/Skeleton";

import DeleteDialog from "../reusable/DeleteDialog";
// import DeleteButton from "../reusable/DeleteIcon";

const ShopTable = ({
  data = [],
  handleUpdate,
  loading,
  updateload,
  errorMessage,
  handleDelete,
}) => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-label-success1";
      case "inactive":
        return "bg-label-danger1";
      default:
        return "bg-label-default";
    }
  };
  const filteredData = data.filter((shop) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (shop.name && shop.name.toLowerCase().startsWith(searchLower)) ||
      (shop.phone && shop.phone.toLowerCase().startsWith(searchLower)) ||
      (shop.registerno && shop.registerno.toLowerCase().startsWith(searchTerm)) ||
      (shop.state && shop.state.toLowerCase().startsWith(searchTerm)) ||
      (shop.code && shop.code.toLowerCase().startsWith(searchTerm)) ||
      (shop.status && shop.status.toLowerCase().startsWith(searchTerm))
    );
  });
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedShop(null);
  };

  const openEditModal = (shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const updateShop = (updatedShopData) => {
    handleUpdate(updatedShopData);
    handleCloseDialog();
  };
  const confirmDelete = () => {
    handleDelete(selectedShop._id);
    setIsDeleteDialogOpen(false);
  };
  const openDeleteDialog = (shop) => {
    setSelectedShop(shop);
    setIsDeleteDialogOpen(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
           
            <div className="row g-2">
              <div className="col-sm-8 card-header pt-4 ">
                <h6 className="mb-0">Shop List</h6>
              </div>

              <div className="col-sm-4">
                <div className="input-group card-header py-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search shop..."
                    fdprocessedid="vlw39b"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="input-group-text"
                    fdprocessedid="1vtzoc"
                    style={{ backgroundColor: "#ededff" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{
                  flex: "1",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <table className="table align-middle mb-0">
                  <thead
                    className="table-light"
                    style={{
                      background: "#f1f1f1",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <tr>
                      <th className="text-center">S No</th>
                      <th className="text-center">Shop Code</th>
                      <th className="text-left">Shop Name</th>
                      <th className="text-center">Phone Number</th>
                      <th className="text-center">Reg No</th>
                      <th className="text-center">State</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      [...Array(5)].map((_, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <Skeleton variant="text" width={40} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={400} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={200} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={200} />
                          </td>
                          {/* <td className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Skeleton variant="rectangular" width={40} height={40} />
                          </td> */}
                        </tr>
                      ))
                    ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData.map((shop, index) => (
                        <tr key={`${shop.id}-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3",
                                backgroundColor: "rgb(13 110 253 / .11)",
                              }}
                              className="badge rounded-pill"
                            >
                              {shop.shopcode}
                            </span>
                          </td>
                          <td className="text-left">
                          {shop.name.charAt(0).toUpperCase() + shop.name.slice(1)}
                          </td>
                          <td className="text-center">{shop.phone || ""}</td>
                          <td className="text-center">{shop.registerno || ""}</td>
                          <td className="text-center">
                            {shop.address?.state || "No State"}
                          </td>
                          <td className="text-center">
                            {shop.status && (
                              <span
                                className={`badge rounded-pill ${getColor(
                                  shop.status
                                )}`}
                              >
                                {shop.status.charAt(0).toUpperCase() +
                                  shop.status.slice(1).toLowerCase()}
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-block order-actions text-center">
                              <EditButton onEdit={() => openEditModal(shop)} />
                              {/* <DeleteButton onDelete={() => openDeleteDialog(shop)}/> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          There are no shops available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditShopDialog
          shop={selectedShop}
          onClose={handleCloseDialog}
          updateShop={updateShop}
          updateload={updateload}
          errorMessage={errorMessage}
        />
      )}

      {isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name="shop"
        />
      )}
    </>
  );
};

export default ShopTable;
