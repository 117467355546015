import React, { useState, useRef } from "react";
import "../Products/product.css";
import { InputAdornment } from "@mui/material";

import CancelButton from "../../reusable/Buttons/CancelButton";
import AddButton from "../../reusable/Buttons/AddButton";
import ButtonWithProgress from "../../reusable/Buttons/ButtonWithProgress";
function AddProductDialog({ onclose, addProduct, buttonloading ,shopCodeError,
  setShopCodeError,
  
}) {
  const [shopData, setShopData] = useState({
    code: "",
    title: "",
    mrp: "",
    saleprice: "",
  });
  const [errors, setError] = useState({});
  const productCodeRef = useRef(null);
  const productTitleRef = useRef(null);
  const productMrpRef = useRef(null);
  const productsalepriceRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // let trimmedValue = name === "email" ? value.replace(/\s/g, "") : value.trimStart().replace(/\s+/g, ' ');
    let trimmedValue;
    if (name === "code" || name === "mrp" || name === "saleprice") {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }
    setShopData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
    if (name === "shopcode") {
      setShopCodeError("");
    }
  };
  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!shopData.title) {
      newErrors.title = "This field must not be empty. Please enter a value.";
      valid = false;
      productTitleRef.current.focus();
    } else if (!shopData.code) {
      newErrors.code = "This field must not be empty. Please enter a value.";
      valid = false;
      productCodeRef.current.focus();
    } else if (!shopData.mrp) {
      newErrors.mrp = "This field must not be empty. Please enter a value.";
      valid = false;
      productMrpRef.current.focus();
    } else if (!shopData.saleprice) {
      newErrors.saleprice =
        "This field must not be empty. Please enter a value.";
      valid = false;
      productsalepriceRef.current.focus();
    }

    setError(newErrors);
    return valid;
  };
  const handleAdd = () => {
    console.log("add", shopData);
    if (!validateFields()) {
      return;
    }
    setShopCodeError("");
    addProduct(shopData);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleSellingPriceChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setShopData((prevData) => ({
      ...prevData,
      saleprice: sanitizedValue,
    }));

    const mrpValue = parseFloat(shopData.mrp);
    const salePriceValue = parseFloat(sanitizedValue) || 0;

    if (salePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "*Sale price must be less than MRP",
        mrp: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "",
      }));
    }

    if (mrpValue <= salePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "*MRP must be greater than Sale price",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }
  };

  const handleMrpChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setShopData((prevData) => ({
      ...prevData,
      mrp: sanitizedValue,
    }));

    const salePriceValue = parseFloat(shopData.saleprice);
    const mrpValue = parseFloat(sanitizedValue) || 0;

    if (mrpValue <= salePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "*MRP must be greater than Sale price",
        saleprice: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }

    if (salePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "*Sale price must be less than MRP",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "",
      }));
    }
  };

  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onclose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-scrollable"
          onClick={stopPropagation}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Add Product</h6>
              <button
                type="button"
                onClick={onclose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      autoComplete="off"
                      value={shopData.title}
                      onChange={handleChange}
                      ref={productTitleRef}
                    />
                    {errors.title && (
                      <div className="error-message invalid-feedback">
                        {errors.title}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <label className="form-label">
                      Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="code"
                      value={shopData.code}
                      onChange={handleChange}
                      ref={productCodeRef}
                    />
                    {errors.code && (
                      <div className="error-message invalid-feedback">
                        {errors.code}
                      </div>
                    )}
                      {shopCodeError && (
                      <div className="error-message invalid-feedback">
                        {shopCodeError}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label">
                      MRP <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mrp"
                      autoComplete="off"
                      placeholder="₹0"
                      value={shopData.mrp}
                      onChange={handleMrpChange}
                      ref={productMrpRef}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "black" }}
                          >
                            <b>₹</b>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.mrp && (
                      <div className="error-message invalid-feedback">
                        {errors.mrp}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label">
                      Selling Rate (Including Tax)
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="saleprice"
                      autoComplete="off"
                      placeholder="₹0"
                      value={shopData.saleprice}
                      onChange={handleSellingPriceChange}
                      ref={productsalepriceRef}
                    />
                    {errors.saleprice && (
                      <div className="error-message invalid-feedback">
                        {errors.saleprice}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer d-block">
              <CancelButton
                buttonText="Cancel"
                buttonIcon="bx bx-x"
                onClick={onclose}
                additionalClasses="btn-outline-secondary text-left"
              />

              {buttonloading ? (
                <ButtonWithProgress
                  label="Adding...."
                  iconClass="bx bx-plus"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleAdd}
                />
              ) : (
                <AddButton
                  label="Add"
                  iconClass="bx bx-plus"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleAdd}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductDialog;
