import React from 'react';

function DeleteButton({ onDelete }) {
  return (
    <button
      type="button"
      className="btn-label-danger shadow-none"
      onClick={onDelete}
    >
      <i className="bx bx-trash"></i>
    </button>
  );
}

export default DeleteButton;
