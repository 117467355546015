import React from 'react';

function EditButton({ onEdit }) {
  return (
    <button
      type="button"
            className=" btn-label-primary shadow-none"
      // className="btn-label-light shadow-none"
      style={{ marginRight: '4px' }}
      onClick={onEdit}
    >
      <i className="bx bx-pencil"></i>
    </button>
  );
}

export default EditButton;
