import React, { useState, useEffect, useRef } from "react";
import "../Products/product.css";

import CancelButton from "../../reusable/Buttons/CancelButton";
import AddButton from "../../reusable/Buttons/AddButton";
import ButtonWithProgress from "../../reusable/Buttons/ButtonWithProgress";

function EditProduct({ onClose, updateProduct, productData, buttonloading }) {
  const [isProductData, setProductData] = useState({
    productid: "",
    title: "",
    code: "",
    mrp: "",
    saleprice: "",
  });
  const [errors, setError] = useState({});
  const productCodeRef = useRef(null);
  const productTitleRef = useRef(null);
  const productMrpRef = useRef(null);
  const productsalepriceRef = useRef(null);
  useEffect(() => {
    if (productData) {
      setProductData({
        productid: productData._id,
        code: productData.code,
        title: productData.title,
        mrp: productData.mrp,
        saleprice: productData.saleprice,
      });
    }
  }, [productData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // let trimmedValue = name === "code" ? value.replace(/\s/g, "") : value.trimStart().replace(/\s+/g, ' ');
    let trimmedValue;
    if (name === "code" || name === "mrp" || name === "saleprice") {
      trimmedValue = value.replace(/\s/g, ""); 
  } else {
      trimmedValue = value.trimStart().replace(/\s+/g, ' '); 
  };

    setProductData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!isProductData.title) {
      newErrors.title = "This field must not be empty. Please enter a value.";
      valid = false;
      productTitleRef.current.focus();
    } else if (!isProductData.code) {
      newErrors.code = "This field must not be empty. Please enter a value.";
      valid = false;
      productCodeRef.current.focus();
    } else if (!isProductData.mrp) {
      newErrors.mrp = "This field must not be empty. Please enter a value.";
      valid = false;
      productMrpRef.current.focus();
    } else if (!isProductData.saleprice) {
      newErrors.saleprice =
        "This field must not be empty. Please enter a value.";
      valid = false;
      productsalepriceRef.current.focus();
    }
    setError(newErrors);
    return valid;
  };
  const handleUpdate = () => {
    const { saleprice, mrp } = isProductData;
    const salePriceValue = parseFloat(saleprice);
    const mrpValue = parseFloat(mrp);

    if (!validateFields()) {
      return;
    }
    if (salePriceValue >= mrpValue) {
      setError({
        saleprice: "*Sale price must be less than MRP",
        mrp: "",
      });
      console.error("Sale price must be less than MRP.");
      return;
    }

    console.log("update", isProductData);
    updateProduct(isProductData);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleSellingPriceChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setProductData((prevData) => ({
      ...prevData,
      saleprice: sanitizedValue,
    }));

    const mrpValue = parseFloat(isProductData.mrp);
    const salePriceValue = parseFloat(sanitizedValue) || 0;

    if (salePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "*Sale price must be less than MRP",
        mrp: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "",
      }));
    }

    if (mrpValue <= salePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "*MRP must be greater than Sale price",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }
  };

  const handleMrpChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setProductData((prevData) => ({
      ...prevData,
      mrp: sanitizedValue,
    }));

    const salePriceValue = parseFloat(isProductData.saleprice);
    const mrpValue = parseFloat(sanitizedValue) || 0;

    if (mrpValue <= salePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "*MRP must be greater than Sale price",
        saleprice: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }

    if (salePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "*Sale price must be less than MRP",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        saleprice: "",
      }));
    }
  };

  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-scrollable"
          onClick={stopPropagation}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Edit Product</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                       autoComplete="off"
                      value={isProductData.title}
                      onChange={handleChange}
                      ref={productTitleRef}
                    />
                    {errors.title && (
                      <div className="error-message invalid-feedback">
                        {errors.title}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <label className="form-label">
                      Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                       autoComplete="off"
                      value={isProductData.code}
                      onChange={handleChange}
                      ref={productCodeRef}
                    />
                    {errors.code && (
                      <div className="error-message invalid-feedback">
                        {errors.code}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label">
                      MRP <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mrp"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.mrp}
                      onChange={handleMrpChange}
                      ref={productMrpRef}
                    />
                    {errors.mrp && (
                      <div className="error-message invalid-feedback">
                        {errors.mrp}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label className="form-label">
                    Selling Rate (Including Tax) <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="saleprice"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.saleprice}
                      onChange={handleSellingPriceChange}
                      ref={productsalepriceRef}
                    />
                    {errors.saleprice && (
                      <div className="error-message invalid-feedback">
                        {errors.saleprice}
                      </div>
                    )}
                  </div>
             
                </div>
              </form>
            </div>
            <div className="modal-footer d-block">

            <CancelButton
                              buttonText="Cancel"
                              buttonIcon="bx bx-x"
                              onClick={onClose}
                              additionalClasses="btn-outline-secondary text-left"
                            />
                  {buttonloading ? (
                    <ButtonWithProgress label="  Updating..."
                    iconClass="bx bx-check"
                    buttonClass="btn btn-primary pull-right"
                    onClick={handleUpdate}/>
                     
                   
                  ) : (
                    <AddButton label="Update"
                      iconClass="bx bx-check"
                      buttonClass="btn btn-primary pull-right"
                      onClick={handleUpdate}/>
                     
                   
                  )}
              </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
