import React, { useState } from "react";
import EditButton from "../../reusable/EditIcon";

import DeleteButton from "../../reusable/DeleteIcon";

import DeleteDialog from "../../reusable/DeleteDialog";
import { Skeleton } from "@mui/material";
import EditAgentDialog from "./EditAgentDialog";



const UserTable = ({ data = [], handleUpdate ,handleDelete,loading ,buttonloading}) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = data.filter((agent) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (agent.name && agent.name.toLowerCase().startsWith(searchLower)) ||
      (agent.usercode && agent.usercode.toLowerCase().startsWith(searchLower)) ||
      (agent.phone && agent.phone.toLowerCase().startsWith(searchTerm)) ||
      (agent.email && agent.email.toLowerCase().startsWith(searchTerm)) 
      
    );
  });
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedAgent(null);
  };

  const openEditModal = (product) => {
    console.log("edit",product._id);
    setSelectedAgent(product);
    setIsDialogOpen(true);
  };
  const openDeleteDialog = (product) => {
    setSelectedAgent(product);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(selectedAgent._id);
    setIsDeleteDialogOpen(false); 
  };
  const UpdateProduct = (updatedProductData) => {
    console.log("updates",updatedProductData._id);
    handleUpdate(updatedProductData);
    handleCloseDialog();
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
          <div className="row g-2">
              <div className="col-sm-8 card-header pt-4 ">
                <h6 className="mb-0">Agent List</h6>
              </div>

              <div className="col-sm-4">
                <div className="input-group card-header py-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    fdprocessedid="vlw39b"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="input-group-text"
                    fdprocessedid="1vtzoc"
                    style={{ backgroundColor: "#ededff" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive"  style={{
                  flex: '1', 
                  overflowY: 'auto', 
                  maxHeight: 'calc(100vh - 200px)', 
                }} >
                <table className="table align-middle mb-0" >
                  <thead
                    className="table-light"
                    style={{ background: "#f1f1f1" , position: "sticky", top: 0,  }}
                  >
                    <tr>
                      <th className="text-center">S No</th>
                      <th className="text-center">User Name</th>
                      <th className="text-center">Password</th>
                      <th>Name</th>
                      <th className="text-center">Phone Number</th>

                      <th className="text-center">Email</th>

                  
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {loading ? (
                      
                      [...Array(5)].map((_, index) => (
                        <tr key={index}>
                          <td className="text-center" >
                            <Skeleton variant="text" width={40} />
                          </td>

                          <td className="text-center" >
                            <Skeleton variant="text" width={80} />
                          </td>
                       
                          <td className="text-center">
                            <Skeleton variant="text" width={500} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={300} />
                          </td>
                          <td className="text-center">
                            <Skeleton variant="text" width={100} />
                          </td>
                         
                        </tr>
                      ))
                    ) :Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData.map((user, index) => (
                      <tr key={`${user._id}-${index}`}>
                        <td className="text-center">{index + 1}</td>

                        <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3",
                                backgroundColor: "rgb(13 110 253 / .11)",
                              }}
                              className="badge rounded-pill "
                            >
                              {user.usercode}
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                             
                              className="badge rounded-pill bg-label-blue"
                            >
                              {user.passwordstr}
                            </span>
                          </td>
                        <td className="text-left">
                          <h6 className="mb-0 fw-500">
                          {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                       
                            
                            </h6>
                        </td>
                     

                        
                      
                        <td className="text-center">{user.phone}</td>
                          
                  
                          
                        <td className="text-center">{user.email || ""}</td>
                   
                        <td>
                          <div className="d-block order-actions text-center">
                            <EditButton onEdit={() => openEditModal(user)} />
                            <DeleteButton onDelete={() => openDeleteDialog(user)}/>
                          </div>
                        </td>
                      </tr>
                     ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditAgentDialog
        editData={selectedAgent}
          onClose={handleCloseDialog}
          editUser={UpdateProduct}
          buttonloading={buttonloading}
        />
      )}

{isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
           Name="agent"
         
        />
      )}
    </>
  );
};

export default UserTable;
