

// export default SideMenu;

import React, { useEffect, useState } from "react";
import { Link ,useLocation} from "react-router-dom";
import logo from '../../styles/images/logo/logo.png';
import '../menu/sidemenu.css';

function SideMenu({ open, setOpen }) {

  const location = useLocation();
const [isMobile, setIsMobile] = useState(window.innerWidth < 1280); 


  const handleLinkClick = () => {
    if (isMobile) {
      setOpen(false); 
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280); 
      
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  const getWrapperClass = () => {
    if (window.innerWidth < 768) {
      return `wrapper ${open ? 'toggled' : ''}`; 
    } else {
      return 'wrapper'; 
    }
  };
  const sidebarClass = open ? 'sidebar-wrapper' : 'sidebar-wrapper closed';
;
  return (
    <>

    <div className={getWrapperClass()}>
    <div className="overlay toggle-icon"onClick={handleLinkClick}></div>
      {/* <div className={`wrapper ${open ? 'toggled' : ''}`}> */}
        <div className={sidebarClass} data-simplebar="true">
          <div className="sidebar-header">
            <div className="logo-container"> 
            {isMobile ? (
                <div style={{display: 'flex'}}>
                  <img src={logo} className="logo-image" alt="logo icon" />
                  <div className="toggle-icon ms-auto" onClick={() => setOpen(!open)}>
                    <i className="bx bx-arrow-back" style={{color:'white', marginTop:'8px'}}></i>
                  </div> 
                </div>
              ) : (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={logo} style={{ width: '65px',marginLeft:'65px',marginRight:'80px' }} alt="logo icon" />
                </div>
              )}
            </div>
          </div>

          {open && (
            
            <ul className="metismenu" id="menu">
              <li>
                <Link to="/dashboard" onClick={handleLinkClick} className={location.pathname === '/dashboard' ? 'active' : ''}>
                  <div className="parent-icon">
                    <i class='bx bxs-home'></i>
                  </div>
                  <div className="menu-title">Dashboard</div>
                </Link>
              </li>
              <li>
                <Link to="/shop" onClick={handleLinkClick} className={location.pathname === '/shop' ? 'active' : ''}>
                  <div className="parent-icon">
                    <i class='bx bxs-store'></i>
                  </div>
                  <div className="menu-title">Shop</div>
                </Link>
              </li>
   
              <li>
                <Link to="/Product" onClick={handleLinkClick} className={location.pathname === '/Product' ? 'active' : ''}>
                  <div className="parent-icon">
                    <i class='bx bx-barcode-reader'></i>
                  </div>
                  <div className="menu-title">Products</div>
                </Link>
              </li>

              <li>
                <Link to="/agent" onClick={handleLinkClick} className={location.pathname === '/agent' ? 'active' : ''}>
                  <div className="parent-icon">
                    <i class='bx bx-user'></i>
                  </div>
                  <div className="menu-title">Agent</div>
                </Link>
              </li>


              <li>
              
                  <Link
                    to="order"
                    onClick={handleLinkClick} className={location.pathname === '/order' ? 'active' : ''}>
                  
                    <div className="parent-icon">
                    <i class='bx bxs-cart-add'></i>
                    </div>
                    <div className="menu-title">Orders</div>
                  </Link>
                
                
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default SideMenu;