// AddButton.js tick with add
import React from 'react';

const AddButton = ({ label, iconClass, buttonClass, onClick }) => {
  return (
    <button type="button" className={`btn ${buttonClass}`} onClick={onClick}>
      <i className={iconClass} ></i> {label}
    </button>
  );
};

export default AddButton;
