import React, { useEffect, useState } from 'react'
import OrderTable from './OrderTable'
import ViewOrders from './ViewOrders';
import useErrorHandle from '../../error/ErrorHandling';


function Orders({authToken}) {
   
  const [loading, setLoading] = useState(false);
  const [isOrders, setOrders] = useState(false);

  
    const [selectedOrder, setSelectedOrder] = useState(null);
    const handleError = useErrorHandle();
    const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
   
    const handleView = async (order) => {
   
      const url = `${apiUrl_Alagappa}view-shop-order`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          shopid:order.shopid,
    orderid: order._id
        })
      };
  
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          await handleError(response);
        }
        const data = await response.json();
        console.log(data);
       
        setSelectedOrder(data.results);

      } catch (error) {
        console.error("Error fetching order:", error);
      }finally {
             
       
        console.log("false load");
    }
    };


    
      const handleClose = () => {
        setSelectedOrder(null);
      };

    const fetchOrders = async (shopid = "", status = "" ) => {
        console.log("get ishopidds",shopid)
        setLoading(true);
        const url = `${apiUrl_Alagappa}list-shop-order`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ 
            shopid: shopid,
            status:status
          }),
        };
    
        try {                                                                       
          const response = await fetch(url, options);
          if (!response.ok) {
            await handleError(response);
          }
          const data = await response.json();
          console.log(data);
      
          setOrders(data.results);

        } catch (error) {
          console.error("Error fetching order:", error);
        }finally {
               
          setLoading(false);
          console.log("false load");
      }
      };
    
      useEffect(() => {
        console.log("Current Access Token:", authToken);
          
          fetchOrders("","CREATED");
      }, []);




  return (
    <div>
       <div className="row">
       <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
<OrderTable data={isOrders}  onView={handleView} loading={loading} listData={fetchOrders} />
       </div>
       <div className="col-lg-4 col-md-6 col-sm-12">
       {/* {selectedOrder && ( */}
            <ViewOrders order={selectedOrder} onClose={handleClose} setOrders={setOrders}  />
       {/* )} */}
     
        </div>
        </div>
      
    </div>
  )
}

export default Orders
