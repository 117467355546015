import React, {useEffect, useState } from "react";
import "../Products/product.css";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import useErrorHandle from "../../error/ErrorHandling";


import AddButton from "../../reusable/Buttons/AddButton";

import AddAgentDialog from "./AddAgentDialog";
import UserTable from "./UserTable";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Agent({ authToken }) {
  const [isUserData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [shopCodeError, setShopCodeError] = useState();
  const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
  const [buttonloading, setButtonLoading] = useState(false);
  const handleError = useErrorHandle();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchUserData = async () => {
    setLoading(true);
    const url = `${apiUrl_Alagappa}list-user`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await handleError(response);
      }
      const data = await response.json();
      console.log(data);
      setUserData(data.results);
    } catch (error) {
      console.error("Error fetching shops:", error);
    }finally {
           
      setLoading(false);
      console.log("false load");
  }
  };

  useEffect(() => {
    console.log("Current Access Token:", authToken);
    fetchUserData();
  }, []);

  const addUser = async (newProduct) => {
    setButtonLoading(true)
   

    const url = `${apiUrl_Alagappa}add-user`;
    const requestBody = {
        name: newProduct.name,
        phone: newProduct.phone
      };
      if (newProduct.email) {
        requestBody.email = newProduct.email;
      }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (!response.ok) {
              
      
            
            await handleError(response);
       
        setButtonLoading(false);
      
    }
      setUserData((preUser) => {
        console.log('Previous Agent:', preUser);
        return [...(preUser || []), data.results]; 
    });
    
console.log("user",isUserData)
      setSnackbarSeverity("success");
      setSnackbarMessage("Added successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false)
      closeModal();
    } catch (error) {
      console.error("Error adding agent data:", error);
      setButtonLoading(false)
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message || "An error occurred. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleUpdate = async (newagent) => {
    setButtonLoading(true)
   

    const url = `${apiUrl_Alagappa}update-user`;
    const requestBody = {
      userid:newagent._id,
        name: newagent.name,
        phone: newagent.phone
      };
      if (newagent.email) {
        requestBody.email = newagent.email;
      }
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (!response.ok) {
              
      
            
            await handleError(response);
       
        setButtonLoading(false);
      
    }
    setUserData((prevAgent) => {
      return prevAgent.map((agent) =>
        agent._id === data.results._id ? data.results : agent
      );
    });
   
    console.log("user",isUserData)
      setSnackbarSeverity("success");
      setSnackbarMessage("Updated successfully!");
      setSnackbarOpen(true);
      setButtonLoading(false)
      closeModal();
    } catch (error) {
      console.error("Error adding agent:", error);
      setButtonLoading(false)
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message || "An error occurred. Please try again.");
      setSnackbarOpen(true);
    }
  };
  const handleDelete = async (id) => {
    const url = `${apiUrl_Alagappa}delete-user`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ userid: id }), 
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (!response.ok) {
            console.error("Failed to delete agent data:", data.message || "Unknown error");
            setSnackbarSeverity("error");
            setSnackbarMessage("Error deleting Agent data: " + (data.message || "Unknown error"));
            setSnackbarOpen(true);
            await handleError(response);
            return;
        }

        setUserData((preAgent) => {
            const updatedProducts = preAgent.filter((agent) => agent._id !== id);
            console.log("Updated agent data after deletion:", updatedProducts);
            return updatedProducts;
        });

        setSnackbarSeverity("success");
        setSnackbarMessage("Deleted successfully!");
        setSnackbarOpen(true);
    } catch (error) {
        console.error("Error deleting agent data :", error);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error deleting agent data.");
        setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div className="page-breadcrumb d-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3"></div>
        <div className="ms-auto">
          <div className="btn-group">
          <AddButton label=" Add Agent"
                      iconClass="bx bx-plus"
                      buttonClass="btn btn-primary text-right"
                      onClick={openModal}/>
           

          

          </div>
        </div>
      </div>

      {isModalOpen && (
        <AddAgentDialog onclose={closeModal} addUser={addUser} buttonloading={buttonloading}  setShopCodeError={setShopCodeError} shopCodeError={shopCodeError}/>
      )}

      <UserTable data={isUserData} loading={loading} buttonloading={buttonloading} handleUpdate={handleUpdate} handleDelete={handleDelete}/>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Agent;